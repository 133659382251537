import { useState, useEffect } from "react";

const Hero2 = ({data}) => {
    return (
        <section className='relative bg-blue-400 py-14 text-gray-50' style={{backgroundImage: "url("+data.bg+")",backgroundSize: "cover",backgroundPosition: "center"}}>
            <div className="max-w-screen-xl mx-auto  md:px-8">
                <div className='relative max-w-xl space-y-3 px-4 md:px-0'>
                    <p className=' text-3xl font-semibold sm:text-4xl'>
                       {data.title}
                    </p>
                    <div className='max-w-xl'>
                        <p>
                        {data.subtitle}
                        </p>
                    </div>
                </div>
                <div className='mt-4 justify-between gap-8 md:flex'>
                    <ul className="flex-1 max-w-md space-y-10 px-4 md:px-0">
                        {
                            data.items.map((item, idx) => (
                                <li key={idx} className="flex gap-x-3 mt-12">
                                    <div className="flex-none  w-12 h-12 rounded-full bg-blue-50 text-blue-600 flex items-center justify-center">
                                        {item.icon}
                                    </div>
                                    <div>
                                        <h4 className=" text-2xl font-medium">
                                            {item.name}
                                        </h4>
                                        <p className="text-gray-100 mt-2 text-lg">
                                            {item.desc}
                                        </p>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                    <div className="flex-1 flex flex-col bg-gray-50 mt-0 md:max-w-xl md:rounded-xl  md:shadow-lg md:mt-0">
                    <img src={data.img} alt="" className=" mx-auto sm:w-10/12  " />
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Hero2



