export default ({ data, id }) => {
    return (
        <section id={id} className="py-12" >
            <div className="max-w-screen-xl mx-auto px-4 md:text-center md:px-8">
                <div className="max-w-xl space-y-3 md:mx-auto">
                    <h3 className="text-gray-800 text-4xl font-semibold sm:text-5xl">
                        {data.title}
                    </h3>
                    <p className="text-gray-800 text-1xl font-semibold sm:text-2xl">
                        {data.subtitle}
                    </p>

                </div>


                <div className=' space-y-6  justify-center gap-8 sm:grid sm:grid-cols-2 sm:space-y-0 md:grid-cols-3 2xl:grid-cols-4'>
                    {
                        data.items.map((item, idx) => (
                            <a href={item.link} target={item.title?item.title:'_self'} className=''>
                            <div key={idx} className='shadow-xl items-start relative flex-1 flex   flex-col p-8 rounded-xl border-2'>
                                <div className='mt-4'>
                                <img alt="" className="h-[70px] rounded-full bg-gray-200 p-1" src={item.image} />
                                </div>
                                <div className='mt-8'>
                                    <span className='text-gray-800 text-2xl font-bold '>
                                        {item.title}
                                    </span>
                                </div>
                                <div className='min-h-48 text-left mt-8 text-gray-800 text-lg '>
                                    <p className=' text-gray-800 text-lg'>
                                        {item.content}
                                    </p>

                                </div>
                                


                                {/*  <div className="flex-1 flex items-end">
                                    <a href={item.buttonLink} target={item.target?item.target:'_self'} className='flex items-center justify-center mt-3 px-3 py-3 rounded-full w-full font-semibold text-sm duration-150 text-white bg-blue-600 hover:bg-indigo-500 active:bg-blue-700'>
                                   
                                    {item.buttontitle} 
                                    </a>
                                </div> */}
                            </div>
                            </a>
                        ))
                    }
                </div>

            </div>
        </section>
    )
}