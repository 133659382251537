
const SumStats = ({data,info}) => {

    const stats = [
        {
            data: "35K",
            title: "Customers"
        },
        {
            data: "40+",
            title: "Countries"
        },
        {
            data: "30M+",
            title: "Total revenue"
        },
    ]

    return (
        <section className="py-8 " style={{backgroundImage: "url("+data.bg+")",backgroundSize: "cover",backgroundPosition: "center"}}>
            <div className="max-w-screen-xl mx-auto px-4 md:px-8">
              <div className="max-w-2xl mx-auto text-center">
                    <h3 className="text-gray-800 text-1xl font-semibold sm:text-1xl">
                        {data.title}
                    </h3>
                    <p className="mt-3 text-gray-500">
                    {data.subTitle}
                    </p>
                </div> 
                <div className="mt-1 pt-5 ">
                    <ul className=" flex flex-col gap-4  justify-center sm:flex-row">
                        {
                            data.items && data.items.map((item, idx) => (
                                <li key={idx} className="min-w-60 bg-gray-50 w-full text-center border px-12 py-4 rounded-lg sm:w-auto">
                                    <h4 className="text-2xl text-blue-600 font-semibold">{item.data }</h4>
                                    <p className="mt-3 text-gray-800 font-medium uppercase">{item.title}</p>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </section>
    )
}
export default SumStats