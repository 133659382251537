import React, { useEffect, useState } from "react";
import Hero1 from '../components/Hero1';
import Hero from '../components/Hero';
import PartnerDescCp from '../components/PartnerDescCp';



import { PartnersbanerData,PartnersData } from '../data/data.source';
const PartnerDesc = () => {
  return (<>
    <Hero data={PartnersbanerData} />
    <PartnerDescCp data={PartnersData}  />
  </>
  );
};

export default PartnerDesc