import { useState, useEffect } from "react";

const Aboutcp = ({ data }) => {
    return (
        <section className='relative py-14 text-gray-700' >
            <div className=" mx-auto ">
                <div className='relative space-y-3 px-14 md:px-14'>

                    {data.items.map((item, idx) => (
                        <>
                            <h1 className="text-gray-800 font-bold text-4xl xl:text-5xl">
                                {item.title}
                            </h1>
                           
                            <div className='py-2  text-lg'>
                                <p>
                                    {item.subTitle}
                                </p>
                            </div>
                        </>
                    ))
                    }
                </div>

            </div>
        </section>
    );
}
export default Aboutcp



