import { useState, useEffect } from "react";

const Hero = ({data}) => {


    return (
        <div  id="hero"  className={`bg-blue-400 ${data.screen?"md:h-screen":''}`} style={{backgroundImage: "url("+(data.bg || './banner.png') +")",backgroundSize: "cover",backgroundPosition: "center"}}>

            <section className="relative">
                <div className={`z-10 max-w-screen-xl ${data.screen?"md:h-screen":''} flex justify-center items-center mx-auto px-4 py-28 md:px-8  ${data.screen?"md:py-48":''}`}>
                    <div className="space-y-5 max-w-4xl mx-auto text-center">
                        <h2 className="text-4xl text-white font-extrabold mx-auto md:text-6xl 2xl:text-6xl">
                            {data.title}
                        </h2>
                        <p className="max-w-4xl text-lg md:text-2xl mx-auto text-gray-100  xl:text-3xl">
                        {data.subTitle}
                            
                        </p>
                        <form
                            onSubmit={(e) => e.preventDefault()}
                            className="justify-center items-center gap-x-3 sm:flex">
{/* flex items-center justify-center rounded-full gap-x-2 py-3.5 px-6 mt-3 w-full text-lg text-white font-medium bg-blue-700 hover:bg-blue-600 active:bg-blue-800 duration-150 rounded-lg sm:mt-0 sm:w-auto" */}
                            {  data.buttonTitle && <a  href={data.buttonLink} target="_blank" rel="noreferrer" 
                            className="flex items-center justify-center rounded-full gap-x-2 py-3.5 px-6 mt-3 w-full text-lg text-white font-medium border-2 border-gray-100  hover:border-gray-50 bg-blue-500 bg-opacity-30 hover:bg-blue-500 active:bg-blue-500 duration-150  sm:mt-0 sm:w-auto">
                                 {data.buttonTitle}
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                    <path fillRule="evenodd" d="M2 10a.75.75 0 01.75-.75h12.59l-2.1-1.95a.75.75 0 111.02-1.1l3.5 3.25a.75.75 0 010 1.1l-3.5 3.25a.75.75 0 11-1.02-1.1l2.1-1.95H2.75A.75.75 0 012 10z" clipRule="evenodd" />
                                </svg>
                            </a>}
                        </form>

                    </div>
                </div>
                {/* <div className="absolute inset-0 m-auto max-w-xs h-[357px] blur-[118px] sm:max-w-md md:max-w-lg" style={{ background: "linear-gradient(106.89deg, rgba(192, 132, 252, 0.11) 15.73%, rgba(14, 165, 233, 0.41) 15.74%, rgba(232, 121, 249, 0.26) 56.49%, rgba(79, 70, 229, 0.4) 115.91%)" }}></div> */}
            </section>
        </div>
    )
}
export default Hero