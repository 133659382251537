import React, { useEffect, useState } from "react";
import Aboutcp from '../components/Aboutcp';
import Hero from '../components/Hero';



import { About1Data,About2Data } from '../data/data.source';

const About = () => {
  return (<div  className="min-h-screen">
    <Hero data={About1Data} />
    <Aboutcp data={About2Data}  />
  </ div>
  );
};

export default About