import { useState, useEffect } from "react";

const Hero3 = ({data}) => {
    return (
        <section className='relative py-14 text-gray-700' >
            <div className="max-w-screen-xl mx-auto  md:px-8">
                <div className='relative max-w-xl space-y-3 px-4 md:px-0'>
                <h1 className="text-gray-800 font-bold text-3xl xl:text-4xl">
                       {data.title}
                    </h1>
                    { data.title2 && <p className="text-blue-400 font-bold text-2xl ">{data.title2}</p>}
                    <div className='max-w-xl'>
                        <p>
                        {data.subtitle}
                        </p>
                    </div>
                </div>
                <div className='mt-2 justify-between gap-8 md:flex'>
                    <ul className="flex-1 max-w-md space-y-10 px-4 md:px-0">
                        {
                            data.items.map((item, idx) => (
                                <li key={idx} className="flex gap-x-3 mt-12">

                                    <div>
                                        <h4 className="text-2xl font-medium">
                                            {item.name}
                                        </h4>
                                        <p className="text-gray-800 mt-2 text-1xl">
                                            {item.desc}
                                        </p>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                    <div className="flex-1 flex flex-col  mt-0 md:max-w-xl md:rounded-xl  md:shadow-lg md:mt-0">
                    <img src={data.img} alt="" className=" mx-auto sm:w-10/12  " />
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Hero3



