export default () => {
    return (
        <section className="py-20 " >
            <div className="max-w-screen-xl mx-auto px-4 md:text-center md:px-8">
                <div className="max-w-xl space-y-3 md:mx-auto">

                    <p className="text-gray-700  font-bold text-3xl xl:text-5xl">
                    Application Scenario
                    </p>
    
                </div>
 
            </div>
        </section>
    )
}