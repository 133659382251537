import React from 'react';
import { Data } from './data';


const getLang = () => {

    let browserLang
    if (typeof window !== 'undefined') {
        // eslint-disable-next-line no-underscore-dangle
        browserLang = navigator.language || navigator.userLanguage;
    }
    if (browserLang) {
        const langCode = browserLang.split('-')[0];
        return langCode;
    }
    return 'en';
};
const lng = getLang();
// eslint-disable-next-line no-underscore-dangle
console.log('lng', lng)
const _t = Data.en; //Data[lng] || 

export const NavbarData = {
    logo: "/logo.png",
    startTitle: 'Launch App',
    startLink: "",
    navigation: [
        { title: "Home", path: "/" },
        { title: "Products", items: [{ title: " ModularBlockchain", target: "ModularLayer", path: "https://memolabs.gitbook.io/memo-docs" }, { title: "MemoMegrez", path: "https://memolabs.gitbook.io/memo-megrez/", target: "MemoMegrez" }, { title: "MeeDA", path: "https://memolabs.gitbook.io/meeda", target: "MeeDA" }], path: "/" },
        { title: "Ecosystem", items: [{ title: "Partner", path: "/partner" },{ title: "Drive3", path: "https://drive3.net/", target: "Drive3" }], path: "/status" }, //, { title: "MiddleWare", path: "https://middleware.metamemo.one/", target: "MiddleWare" }
        { title: "Docs", items: [{ title: "Technical", path: "/tec" }, { title: "Wiki", path: "https://wiki.memolabs.org/#/", target: "Wiki" }, { title: "Whitepaper", path: "https://docsend.com/view/jnqpex7", target: "Whitepaper" }], path: "/" },
        { title: "Blog", path: "https://blog.memolabs.org/", target: "Blog" },
        { title: "About", path: "/about" },

    ]
}

export const About1Data = {
    title: 'About Memo Labs',
    subTitle: '',
    bg:"about-cover.jpg",
    buttonTitle: "",
    buttonLink: "",
}
export const About2Data = {
    items: [
        {
            title: 'Who we are',
            subTitle: 'Memo Labs is a laboratory focusing on researching and scaling decentralized cloud storage technology applications. Our mission is to build a reliable infrastructure for Web3.0 and develop robust decentralized storage and application tools.',

        },
        {
            title: 'Technical team',
            subTitle: 'Our members have different professional backgrounds. The R&D team comprises experts, professors and PhDs from many various fields such as blockchain, Internet, decentralized storage, cryptography, etc. The promise of blockchain decentralization and the grand vision of permanent data storage bring us together. We aspire to provide a safer and more reliable solution to perpetuate human civilization.',

        }

    ]
}




export const FooterData1 = {
    emailTitle: 'Stay In Touch, Don\'t Miss Out!',
    buttonTitle: "Subscribe",
    buttonLink: ""
}




export const StatusData3 = {
    title: "",
    subTitle: ' ',
    bg: './advantage-bg.png',
    items: [
        {
            data: "56847",
            title: "Unique Addresses",
            img: "./status1.png"
        },
        {
            data: "2549",
            title: "dApp Contracts",
            img: "./status1.png"
        },
        {
            data: "12264",
            title: "EDGE NODES",
            img: "./status1.png"
        },

    ]
}

export const Home1Data = {
    title: 'AI-Driven User Centric Data Modular Blockchain',
    subTitle: 'MEMO is an AI-driven, user-centric data platform with a modular design, allowing customization and flexibility. It enhances functionality and streamlines data management, empowering users to effectively manage their data according to individual needs. ',
    button: 'Get started',
    buttonTitle: "Start for Free",
    buttonLink: "https://memolabs.gitbook.io/memo-docs",
    button2: '',
    screen: true

    //img: "./home1.png"

}

export const Home11Data = {
    title: '',
    subTitle: '',
    items: [
        {
            name: "Modular Blockchain",
            buttontitle: "Learn More",
            desc: "MEMO modularizes data storage, processing, identity authentication, and verification functions, allowing each component to run independently and be flexibly combined. This design improves the scalability and interoperability of the system and can adapt to different Web3 scenario requirements.",
            buttonLink:"https://memolabs.gitbook.io/memo-docs",
            target: "MemoLayer"
        },
        {
            name: "MegrazNetwork",
            buttontitle: "Learn More",
            desc: "MegrazNetwork is the underlying storage network of MEMO, which adopts a layered architecture, in which key information such as metadata information and service settlement is stored on the blockchain, while other data is stored off-chain to improve cost-effectiveness and processing capabilities.",
            buttonLink: "https://memolabs.gitbook.io/memo-megrez",
            target: "MegrazNetwork"
        },
        {
            name: "MeeDA",
            buttontitle: "Learn More",
            desc: "MeeDA is a data availability solution based on Ethereum security. Blob data is stored off-chain, indexes and commitment proofs are stored on-chain, reducing Rollup transaction costs. It is compatible with any Optimistic type of Layer2 chain and provides data availability guarantees.",
            buttonLink: "https://memolabs.gitbook.io/meeda",
           target: "MeeDA"
            
        }

    ]


}


export const Home12Data = {
    title: 'Outstanding Advantages',
    subTitle: '',
    items: [
        {
            name: "ZK-based data security",
            desc: "ZK technology allows users to perform data verification or transactions (e.g., DID verification, RWA) without the original data being exposed to third parties, greatly enhancing data security and user privacy protection.",
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />
                </svg>
        },
        {
            name: "AI-enabled data resource services",
            desc: "The publicly available data sources as well as resource nodes on MEMO provide rich support for the development of artificial intelligence (AI). These data sources and arithmetic resources are crucial for LLM training and computation of AI.",
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3" />
                </svg>
        },
        {
            name: "Rollup and DA schemes enhance Ethereum scalability",
            desc: "MEMO Ecology's OP-Rollup extension solution, Memolayer, and DA solution, MeeDA, have helped increase the transaction throughput of the Ethereum network, reduce transaction costs, and enhance its scalability.",
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
                </svg>
        },
    ],
    img: './advantage.png',
    bg: './advantage-bg.png',

}


export const Home2Data = {
    title: 'DePIN',
    subTitle: "Through the node network, DePIN facilities are built to provide equipment resources for practical scenarios such as storage and computing. In addition, combined with MEMO's own transmission channel, regional wireless network resources can also be built.",
    button: '',
    button2: '',

    img: "./depin.png"

}

export const Home3Data = {
    title: 'AI',
    subTitle: "Through MEMO's global data network, training data support is provided for AI projects, and storage, processing and smart contract integration of large-scale AI data sets are provided to promote the deep integration of AI and WEB3.",
    button: '',
    button2: '',

    img: "./ai.png"

}

export const Home4Data = {
    title: 'DApp',
    subTitle: "As a decentralized data storage network, MEMO is compatible with mainstream networks such as EVM. It provides solid infrastructure support for the development and deployment of decentralized applications through storage nodes, cross-chain bridges, DA and other solutions.",
    button: '',
    button2: '',

    img: "./dapp.png"

}
export const HomeTimeLineData =
{
    title: "Roadmap",
    bg:"/journey.png",
    items: [
        {
            time: "2024 Q4",
            title: "AI-related features support",
            desc: ``
        }, {
            time: "2024 Q2",
            title: "Improved Storage Ecosystem, Support the middleware",
            desc: ``
        }, {
            time: "2023 Q2",
            title: "Data Verification, and data wallets",
            desc: ``
        }, {
            time: "2022 Q4",
            title: "Megrez Version Online",
            desc: `Improve Safety and Reliability`
        }, {
            time: "2021 Q2",
            title: " Phecda Test Network Online",
            desc: `Start the Provider/User Test`
        }, {
            time: "2020 Q3",
            title: "Upgrade the Proof of Data Storage",
            desc: `Security Audit`
        }, {
            time: "2019 Q4",
            title: "Implement Basic Storage Function",
            desc: `Integrate Blockchain Payment and Incentive System`
        }, {
            time: "2018 Q1",
            title: "System Architecture Design 1.0",
            desc: `White Paper 1.0`
        },{
            time: "2017 Q3",
            title: "Project Start",
            desc: ``

        }


    ]

}


export const Home5Data = {
    title: 'Partners',
    subTitle: "",
    items: [
        "./partners/eth.png",
        "./partners/harmony.png",
        "./partners/metis.png",
        "./partners/snz.png",
        "./partners/chaincapital.png",
        "./partners/bofrost.png",
        
        "./partners/commando.png",
        "./partners/dhvc.png",
        
        "./partners/fantom.png",
        
        "./partners/hashglobal.png",
        "./partners/hashkey.png",
        "./partners/io.net.png",
       
        "./partners/msq.png",
        "./partners/mytoken.png",
        
        "./partners/web3go.png"
    ]
}

export const Home6Data = {
    title: 'In the News',
    subTitle: "",
    items: [
        "./news/ap.png",
        "./news/newsbtc.png",
        "./news/forbes.png",
        "./news/yahoo.png",
        "./news/newsfile.png",
        "./news/techflow.png",
        "./news/coindesk.png",
        "./news/foresight.png",

        "./news/cointelegraph.png",
        "./news/coinspeaker.png",
       
       
    ]
}



export const Technical0Data = {
    title: 'MEMO Data Validation and Security Architecture',
    title2: '',
    subTitle: "",
    button: '',
    button2: '',
};

export const Technical1Data = {
    title: 'The three roles of the system',
    title2: '',
    button: '',
    button2: '',
    items: [
        {
            name: "User",
            desc: "Users of the storage service. Data can be uploaded or downloaded and is subject to a service fee.",
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />
                </svg>
        },
        {
            name: "Provider",
            desc: "Providers of edge storage devices that respond to user requests for data access and management and, at the same time, work with maintainers to repair data.",
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3" />
                </svg>
        },
        {
            name: "Keeper",
            desc: "Responsible for ensuring security, reliability and availability. Maintains data mapping information and uses this information to challenge the repository and reach management consensus.",
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
                </svg>
        },
    ],
    img: "/ptec/t-1.png",
    bg: '/advantage-bg.png',

}


export const Technical2Data = {
    title: 'Storage Proof Process',
    title2: 'Verification Principle',
    subTitle: "For each verification, the Keeper generates a VRF key pair and while saving the VRF private key to the local, and sends the VRF public key to the corresponding Provider, which means that the Provider uses the VRF public key to verify the reliability of the function.  Similarly, the random number generated by the VRF calculation and the response evidence will be sent to the Provider together for the subsequent verification",
    button: '',
    button2: '',
    img: "/ptec/t-2.png",
    bg: '/advantage-bg.png',
}


export const Technical3Data = {
    title: 'Multi-level Fault-tolerant Mechanism',
    subTitle: "Multi-level fault tolerance mechanism is a major foundation for MEMO decentralized cloud storage system to ensure data integrity and reliability. MEMO performs hierarchical design according to the characteristics of various types of data, and combines multi-copies technology and erasure coding technology, striving for a balance between reliability, security and redundancy.",
    items:["Lower redundancy",
     "Higher fault tolerance",
    "Better recovery performance",
    "Guaranteed consistency"],
   
    button: '',
    button2: '',
    img: "/ptec/t-3.png",
    bg: '/advantage-bg.png',
}


export const Technical4Data = {
    title: 'Risk-Aware Failure Identification--RAFI',
    title2: 'How RAFI works ?',
    subTitle: "RAFI classifies risks according to the number of failed data blocks in a stripe. The failed data blocks include all lost data blocks and unusable data blocks. For example, in a stripe with two data blocks, under this classification method, a stripe with two failed data blocks is called a high-risk stripe, and a stripe with one failed data block is called a low-risk stripe.  In this case, a strip with one missing data block and two unusable data blocks are treated as high-risk stripes. High-risk stripes will be preferentially processed to reduce the possibility of data unavailability and loss.",
    button: '',
    button2: '',
    img: "/ptec/t-4.png",
    bg: '/advantage-bg.png',
}

export const PartnersbanerData = {
    title: 'PARTNERS',
    bg:"/partner-cover.jpg",
    subTitle: "In addition to product integration, MEMO has partnerships with many projects to deliver great services to users.",
}
export const PartnersData = {
    title: '',
    subTitle: "",
    items:[
    {
      "title": "METER",
      "link":"https://meter.io/",
      "image": "/partenerp/meter.png",
      "content": "Meter is an open source platform with Freedom and Fairness as the first principle. It is highly decentralized, censorship resistant yet lightning fast & MEV resistant."
    },
    {
      "title": "Metis",
      "link":"https://metis.io/",
      "image": "/partenerp/meti.png",
      "content": "Metis is an Ethereum Layer 2 Rollup platform that offers simple and fast smart contract deployment within the network."
    },
    {
      "title": "Harmony",
      "link":"https://harmony.one/",
      "image": "/partenerp/harmony.png",
      "content": "Harmony is a fast and open layer-1 blockchain running Ethereum dapps with 2-second transaction finality and 100x lower fees."
    },
    {
      "title": "4EVERLAND",
      "link":"https://www.4everland.org/",
      "image": "/partenerp/4everland.png",
      "content": "4EVERLAND is a Web 3.0 cloud computing platform that integrates storage, computing, and network as its core capabilities."
    },
    {
      "title": "Bifrost",
      "link":"https://www.bifrostcloud.com/",
      "image": "/partenerp/bifrost.png",
      "content": "The Bifrost Network is a fast and scalable EVM-compatible blockchain that provides an all-in-one environment for developers to build multichain DApps."
    },
    {
      "title": "Cwallet",
      "link":"https://cwallet.com/",
      "image": "/partenerp/c-wallet.png",
      "content": "Cwallet is a unique multi-functional wallet which offers centralized and decentralized functions together under one app."
    },
    {
      "title": "chainIDE",
      "link":"https://chainide.com/",
      "image": "/partenerp/chainIDE.png",
      "content": "ChainIDE is a cloud-based IDE for creating decentralized applications to deploy on blockchains such as Ethereum, BNB Chain, Polygon, Conflux, Nervos, Flow, Aptos, etc innovations in DeFI, Interoperability, Scalability, freedom of Money."
    },
    {
      "title": "Ethos",
      "image": "/partenerp/ethos.png",
      "link":"https://3thos.club/pc",
      "content": "A mobile application that allows fans and creators to engage with each other more effectively."
    },
    {
      "title": "Getaverse",
      "link":"https://getaverses.com/",
      "image": "/partenerp/getaverse.png",
      "content": "Getaverse is a metaverse ecological service platform based on the Web3 digital authentication engine."
    },
    {
      "title": "TaskOn",
      "link":"https://taskon.xyz/",
      "image": "/partenerp/taskon.png",
      "content": "TaskOn is a platform that boosts the completion for various Web3 tasks in a decentralized way."
    },
    {
      "title": "PickUp",
      "link":"https://www.pickup.social/",
      "image": "/partenerp/pickup.png",
      "content": "PickUp is a landed social metaverse system based on DID protocol multi-chain deployment."
    },
    {
      "title": "KNN3",
      "link":"https://www.knn3.xyz/",
      "image": "/partenerp/knn3.png",
      "content": "KNN3 is one-stop Web3 User-centric #DataFi solution for d/Apps and smart contracts."
    }
  ]};
  




export const FooterData = {

    emailTitle: '',
    button: 'Subscribe',

}
