import React from 'react';

export default function TimeLine({ data }) {

    const  scrollLeft=()=> {
        document.getElementById('scrollableDiv').scrollBy({
            left: -300, // 调整滚动距离
            behavior: 'smooth'
        });
    }

    const scrollRight=()=> {
        document.getElementById('scrollableDiv').scrollBy({
            left: 300, // 调整滚动距离
            behavior: 'smooth'
        });
    }

    const test = {
        title: "",
        items: [
            {
                time: "01.07.2021",
                title: "Title of section 1",
                desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque scelerisque diam non nisi semper, et elementum lorem ornare.Maecenas placerat facilisis mollis. Duis sagittis ligula in sodales vehicula.`

            }, {
                time: "01.07.2021",
                title: "Title of section 1",
                desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque scelerisque diam non nisi semper, et elementum lorem ornare.Maecenas placerat facilisis mollis. Duis sagittis ligula in sodales vehicula.`
            }
            , {
                time: "01.07.2021",
                title: "Title of section 1",
                desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque scelerisque diam non nisi semper, et elementum lorem ornare.Maecenas placerat facilisis mollis. Duis sagittis ligula in sodales vehicula.`
            }
        ]
    }
    return (
        <section className="py-2" style={{ backgroundImage: "url(" + data.bg + ")", backgroundSize: "cover", backgroundPosition: "center" }}>
            <div className="relative w-full  mx-auto ">
                <div className="pt-8  max-w-2xl mx-auto md:text-center relative max-w-xl space-y-3 px-4 md:px-0">
                    <h3 className="text-gray-50 font-semibold text-4xl xl:text-5xl">
                        {data.title}
                    </h3>
                    <p className="mt-3 text-gray-100">
                        {data.subTitle}
                    </p>
                </div>
                <div className='pt-1 md:pt-10 pb-10 flex items-center justify-center w-full'>
                    <div className='flex items-center justify-center'>
                        <a onClick={scrollLeft} target="_blank" rel="noreferrer" className="hidden md:block [&>svg]:text-gray-100 [&>svg]:h-6 [&>svg]:w-6  gap-x-2 py-2.5 px-4 mt-2  text-lg text-white font-medium bg-blue-500 hover:bg-blue-600 active:bg-blue-800 duration-150 rounded-full sm:mt-0 ">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5"></path>
                        </svg>
                        </a>
                    </div>
                   
                    <div id="scrollableDiv" className=" md:max-w-screen-lg text-gray-100 p-2  w-full  md:overflow-x-auto no-scrollbar  mx-auto flex ">
                        <ol className="relative  md:min-w-[2000px]  mt-12   border-s-2 md:gap-6 md:border-s-0 md:border-t-2 border-gray-50  md:flex md:justify-center ">
                            {/* <!--First item--> */}
                            {data.items && data.items.map((item, idx) => (
                                <li className='min-w-[120px] max-w-[420px]'>
                                    <div className="  flex-start flex items-center pt-2 md:block md:pt-0">
                                        <div className=" -ml-[9px] mr-3 h-[18px] w-[18px] rounded-full bg-green-600 -mt-[9px] md:ml-0 md:mr-0"></div>
                                        <p className="mt-2 font-bold text-lg text-gray-100">
                                            {item.time}
                                        </p>
                                    </div>
                                    <div className="ml-4 mt-2 pb-5 md:ml-0">
                                        <h4 className="mb-1.5 text-lg text-gray-100 font-semibold">
                                            {item.title}
                                        </h4>
                                        <p className="mb-3  text-gray-200">
                                            {item.desc}
                                        </p>
                                    </div>
                                </li>
                            ))}

                        </ol>
                    </div>
                   
                    <div className=' flex items-center justify-center'>
                        <a onClick={scrollRight} target="_blank" rel="noreferrer" className="hidden md:block [&>svg]:text-gray-100 [&>svg]:h-6 [&>svg]:w-6 gap-x-2 py-2.5 px-4 mt-2  text-lg text-white font-medium bg-blue-600 hover:bg-blue-700 active:bg-blue-800 duration-150 rounded-full sm:mt-0 ">
           
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5"></path>
                        </svg>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
}