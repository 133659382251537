import React, { useEffect, useState } from "react";
import Hero1 from '../components/Hero1';
import Hero from '../components/Hero';
import Hero2 from '../components/Hero2';
import CTA from '../components/CTA';
import SumStats from '../components/SumStats';
import Features from '../components/Features';
import Features2 from '../components/Features2';
import Partners from '../components/Partners';
import TimeLine from '../components/TimeLine';


import { Home1Data, Home11Data, Home12Data, StatusData3, Home2Data, Home3Data, Home4Data,HomeTimeLineData, Home5Data, Home6Data, FeaturesData } from '../data/data.source';

const Home = () => {
  return (<>
    <Hero data={Home1Data} />
    <Features2 data={Home11Data} />
    <SumStats data={StatusData3} />
    <Hero2 data={Home12Data} />
    <CTA/>
    <Hero1 data={Home2Data} />
    <Hero1 data={Home3Data} imgleft />
    <Hero1 data={Home4Data} />
    <TimeLine data={HomeTimeLineData} />
    <Partners id="partners" data={Home5Data} />
    <Partners data={Home6Data} />

  </>
  );
};

export default Home