import React, { useEffect, useState } from "react";
import Hero1 from '../components/Hero1';
import Hero from '../components/Hero';
import Hero3 from '../components/Hero3';
import SumStats from '../components/SumStats';
import Features2 from '../components/Features2';
import Partners from '../components/Partners';


import { Home1Data,Technical0Data,Technical1Data,Technical2Data,Technical3Data,Technical4Data } from '../data/data.source';

const Technical = () => {
  return (<>
    <Hero data={Technical0Data} />
    <Hero3 data={Technical1Data}  />
    <Hero1 data={Technical2Data} imgleft/>
    <Hero1 data={Technical3Data}  />
    <Hero1 data={Technical4Data} imgleft/>
   

  </>
  );
};

export default Technical