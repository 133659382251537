// App.js

import React,{useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


import NotFound from './components/NotFound';
import Navbar from './components/Navbar';
import Footer2 from './components/Footer2';
import Home from './pages/Home';
import Technical from './pages/Technical';
import About from './pages/About';
import PartnerDesc from './pages/PartnerDesc';


import {NavbarData} from './data/data.source';
import {FooterData1} from './data/data.source';



function App() {
  return (
    <div>
    <Router>
    <Navbar data={NavbarData}/>
      <div>
        <Routes>
           <Route exact path="/" element={<Home/>} />
           <Route exact path="/tec" element={<Technical/>} />
           
          <Route exact path="/home" element={<Home/>} />
          <Route exact path="/about" element={<About/>} />
          <Route exact path="/partner" element={<PartnerDesc/>} />
          
          <Route element={<NotFound/>} />
        </Routes>
      </div>
    </Router>
    <Footer2 data={FooterData1}/>
    </div>
  );
}

export default App;
