const Features2 = ({data}) => {



    return (
        <section className='py-4  mt-16 mb-16'>
            <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
                <div className='relative max-w-xl mx-auto sm:text-center'>
                    <h3 className='text-gray-800 text-3xl font-semibold sm:text-4xl'>
                    {data.title}
                    </h3>
                    <div className='mt-3 max-w-xl'>
                        <p>
                        {data.subtitle}
                        </p>
                    </div>
                </div>
                <div className=' space-y-6  justify-center gap-6 sm:grid sm:grid-cols-2 sm:space-y-0 lg:grid-cols-3'>
                    {
                        data.items.map((item, idx) => (
                            <div key={idx} className='shadow-xl relative flex-1 flex items-stretch border-blue-100 flex-col p-8 rounded-xl border-2'>
                                <div>
                                    <span className=' text-gray-800 text-2xl font-semibold '>
                                        {item.name}
                                    </span>
                                    <div className='mt-4 text-gray-800 text-lg '>
                                        {item.desc} 
                                    </div>
                                </div>

                                <div className="flex-1 flex items-end">
                                    <a href={item.buttonLink} target={item.target?item.target:'_self'} className='flex items-center justify-center mt-3 px-3 py-3 rounded-full w-full font-semibold text-sm duration-150 text-white bg-blue-600 hover:bg-indigo-500 active:bg-blue-700'>
                                   
                                    {item.buttontitle} 
                                    </a>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    );
};


export default Features2